import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { ACTION_ERROR_INTER } from './constant';
import { Component } from './component';
import { removeAction } from './action';
import { PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME } from '../profile-psycho-category-list';
import { action as fetch } from './action';
import { required } from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  ANIMAL_DATA_INTER,
  ANIMAL_TYPE_ENUM,
} from '../../data/animal/constant';
import {
  config,
  FORM_VALUE_ENUM,
  FORM_VALUE_TYPE,
} from '../profile-user-create-animal/constant';
import { FILE_UPLOAD_INTER } from '../../data/file/constant';

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC<{
  data: ANIMAL_DATA_INTER;
  onSuccess?: Function;
  onToggle: () => void;
  isOpen: boolean;
}> = ({ data, onSuccess, onToggle, isOpen }) => {
  const queryClient = useQueryClient();

  const [photo, setPhoto] = useState<File | FILE_UPLOAD_INTER | null>(
    data?.photo ? data.photo : null,
  );

  const [certificate, setCertificate] = useState<
    File | FILE_UPLOAD_INTER | null
  >((data?.dogVaccinationPassport || data.catVaccinationPassport) ?? null);

  const setPhotoState = (e: File | FILE_UPLOAD_INTER) => {
    setPhoto(e);
    formik.setFieldValue(FORM_VALUE_ENUM.PHOTO, e);
  };

  const setCertificateState = (e: File | FILE_UPLOAD_INTER) => {
    setCertificate(e);

    if (formik.values[FORM_VALUE_ENUM.TYPE] === ANIMAL_TYPE_ENUM.DOG) {
      formik.setFieldValue(FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT, e);
    } else {
      formik.setFieldValue(FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT, e);
    }
  };

  const onDeletePhoto = () => {
    setPhoto(null);
    formik.setFieldValue(FORM_VALUE_ENUM.PHOTO, null);
  };

  const onDeleteCertificate = () => {
    setCertificate(null);

    if (formik.values[FORM_VALUE_ENUM.TYPE] === ANIMAL_TYPE_ENUM.DOG) {
      formik.setFieldValue(FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT, null);
    } else {
      formik.setFieldValue(FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT, null);
    }
  };

  const initialValues = {
    [FORM_VALUE_ENUM.TYPE]: data.type,
    [FORM_VALUE_ENUM.NAME]: data?.name,
    [FORM_VALUE_ENUM.PHOTO]: null,
    [FORM_VALUE_ENUM.DESCRIPTION]: data?.description,
    [FORM_VALUE_ENUM.HAS_SPECIAL_NEEDS]: data?.hasSpecialNeeds,
    [FORM_VALUE_ENUM.SPECIAL_NEEDS_DESCRIPTION]: data?.specialNeedsDescription,
    [FORM_VALUE_ENUM.DOG_BREED]: data?.dogBreed,
    [FORM_VALUE_ENUM.DOG_AGE]: data?.dogAge,
    [FORM_VALUE_ENUM.DOG_WEIGHT_OVER_THIRTYKG]: data?.dogWeightOverThirtykg,
    [FORM_VALUE_ENUM.DOG_PULLS_LEASH]: data?.dogPullsLeash,
    [FORM_VALUE_ENUM.DOG_PICKS_FROM_GROUND]: data?.dogPicksFromGround,
    [FORM_VALUE_ENUM.DOG_CANT_TAKE_AWAY]: data?.dogCantTakeAway,
    [FORM_VALUE_ENUM.DOG_CAN_STAY_ALONE]: data?.dogCanStayAlone,
    [FORM_VALUE_ENUM.DOG_FEAR]: data?.dogFear,
    [FORM_VALUE_ENUM.DOG_IS_AGGRESSIVE]: data?.dogIsAggressive,
    [FORM_VALUE_ENUM.DOG_AGGRESSION_REASON]: data?.dogAggressionReason,
    [FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT]: data?.dogVaccinationPassport,
    [FORM_VALUE_ENUM.CAT_BREED]: data?.catBreed,
    [FORM_VALUE_ENUM.CAT_CAN_BITE]: data?.catCanBite,
    [FORM_VALUE_ENUM.CAT_CAN_STAY_ALONE]: data?.catCanStayAlone,
    [FORM_VALUE_ENUM.CAT_AGE]: data?.catAge,
    [FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT]: data?.catVaccinationPassport,
  };

  const action = useMutation(fetch, {
    onSuccess: () => {
      queryClient.invalidateQueries(PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME);
      // setAddDiplomaCount(0);
      // setAddCertificateCount(0);
      onSuccess && onSuccess();
    },
  });

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('id', data.id);

    Object.values(FORM_VALUE_ENUM).forEach((key) => {
      const value = formik.values[key];

      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        !(typeof value === 'boolean' && value === false)
      ) {
        formData.append(key, String(value));
      }
    });

    if (photo) {
      if (photo instanceof File) {
        formData.append('photo', photo);
      }
    }

    if (certificate) {
      if (certificate instanceof File) {
        if (formik.values[FORM_VALUE_ENUM.TYPE] === ANIMAL_TYPE_ENUM.DOG) {
          formData.append(
            FORM_VALUE_ENUM.DOG_VACCINATION_PASSPORT,
            certificate,
          );
        } else {
          formData.append(
            FORM_VALUE_ENUM.CAT_VACCINATION_PASSPORT,
            certificate,
          );
        }
      }
    }

    action.mutate({ payload: formData });
  };

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: () => {},
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }

    if (deleteAction.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (
    options: { value: number; label: string }[],
    value: any,
  ) => {
    const optionList = options?.filter(
      (item: any) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const deleteAction = useMutation((id: string) => removeAction(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(PROFILE_PSYCHO_LIST_CATEGORY_MODULE_NAME);
      onSuccess && onSuccess();
    },
  });

  const handleDelete = () => {
    deleteAction.mutate(data?.id);
  };

  return (
    <Component
      setPhotoState={setPhotoState}
      setCertificateState={setCertificateState}
      onDeletePhoto={onDeletePhoto}
      onDeleteCertificate={onDeleteCertificate}
      isSuccessDelete={deleteAction.isSuccess}
      handleDelete={handleDelete}
      data={data}
      onToggle={onToggle}
      isOpen={isOpen}
      isLoading={isLoading()}
      setFieldValue={setFieldValue}
      getFieldError={getFieldError}
      isFieldError={isFieldError}
      setValue={setValue}
      getFieldValue={getFieldValue}
      formik={formik}
      handleSubmit={handleSubmit}
      isSubmitDisabled={isSubmitDisabled}
      isError={isError()}
      getErrorMessage={getErrorMessage}
      isSuccess={isSuccess()}
    />
  );
};
